import { t } from "@lingui/macro";
import { CircleNotch } from "@phosphor-icons/react";
import dayjs from "dayjs";
import Cookie from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAppwriteLogin } from "@/client/services/auth";

export const AppwriteLoginPage = () => {
  const [searchParams] = useSearchParams();
  const [sessionTimedOut, setSessionTimedOut] = useState(false);
  const { login } = useAppwriteLogin();
  const navigate = useNavigate();
  // TODO: Add translations
  // eslint-disable-next-line lingui/no-unlocalized-strings
  const loadingText = t`Getting your account ready...`;

  useEffect(() => {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    const isAuthenticated = Cookie.get(`Authentication`);
    if (isAuthenticated) {
      navigate("/dashboard/resumes");
      return;
    }
    if (sessionTimedOut) return;
    const timeStampWithAppwriteJwt = searchParams.get("taj");
    const timeStamp = timeStampWithAppwriteJwt?.split(":")[0];
    const jwt = timeStampWithAppwriteJwt?.split(":")[1];
    if (Number(timeStamp) - dayjs().unix() < 0) {
      setSessionTimedOut(true);
      return;
    }
    if (!jwt) return;
    login({ jwt });
  }, [searchParams]);

  return (
    <div className="flex items-center gap-2 text-base">
      {sessionTimedOut ? (
        t`Session timed out. Please try again.`
      ) : (
        <>
          <CircleNotch className="animate-spin" />
          {loadingText}
        </>
      )}
    </div>
  );
};
